<template>
<b-modal id="modal-create-free-line" ref="modal-create-free-line" centered="centered" :title="(freeLine.id ? 'Modifier': 'Ajouter') + (freeLine.typeLine == '3' ? ' un titre' : freeLine.typeLine == '4' ? ' un sous-titre' :' une ligne libre')" @ok="create">
    <div v-if="isCreatingPlanningLine">
        <div class="text-center">
            <div class="spinner-border text-primary" role="status"><span class="sr-only">Chargement...</span></div><br />Chargement des détails...
        </div>
    </div>
    <validation-observer v-else ref="formCreateFreeLine">

        <b-row>
            <b-col cols="12" class="d-flex">
                <b-form-group label="Libellé" label-for="name" style="flex:1">
                    <validation-provider #default="{ errors }" name="Libellé">
                    <b-form-input
                        id="name"
                        v-model="freeLine.name"
                        :state="errors.length > 0 ? false : null"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="12" class="d-flex" v-if="freeLine.typeLine<=2">
                <b-form-group label="Sous libellé" label-for="subName" style="flex:1">
                    <validation-provider #default="{ errors }" name="Libellé">
                    <b-form-input
                        id="subName"
                        v-model="freeLine.subName"
                        :state="errors.length > 0 ? false : null"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
    </validation-observer><template #modal-footer="{ ok, cancel }">
        <div class="w-100">
            <b-button class="float-left" v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-if="freeLine.id" variant="outline-danger" @click="ok()">Supprimer</b-button>
            <b-button class="float-right" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="ok()">{{freeLine.id ? 'Modifier': 'Ajouter'}}</b-button>
            <b-button class="float-right mr-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="cancel()">Annuler</b-button>
        </div>
    </template>
</b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import VSwatches from "vue-swatches";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      returnFreeLine : false,
      freeLine: {
        id:0,
        name: null,
        subName: null,
        typeLine:0
      },
    };
  },
  methods: {
    popupCreateFreeLineEvent(id, type, returnFreeLine = false){
      this.returnFreeLine = returnFreeLine
      this.freeLine.typeLine=type
      if(id>0){
        this.fetchPlanningLine(id).then(res => {
          this.freeLine = res
        })
      }else{
        this.initializeForm(type, returnFreeLine)
      }
    },
    initializeForm(type) {
      this.freeLine = {
        id:0,
        name: null,
        subName: null,
        typeLine:type
      };
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreateFreeLine.validate().then((success1) => {
        if (success1) {
          if(this.freeLine.id){
            this.updatePlanningLine(this.freeLine)
          }else{
            if(this.returnFreeLine){
                this.$emit('returnLine', this.freeLine)
            }else{
              this.createPlanningLine(this.freeLine).then(res => {
                this.freeLine.id=res.id
              })
            }
          }

          
          this.$nextTick(() => {
            this.$refs["modal-create-free-line"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions(["createPlanningLine", "updatePlanningLine","fetchPlanningLine"]),
  },
  computed: {
    ...mapGetters(["isCreatingPlanningLine"]),
  },
  
  components: {
    ValidationProvider,
    ValidationObserver,
    VSwatches,
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
</style>