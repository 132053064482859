<template>
  <div id="planningView"> 

    
    <b-row class="m-0 h-100">
      <b-col cols="6" class="col-6 d-flex flex-column" style="border-bottom: 1px solid #e2e2e2;">

        <div class="content-header">
          <h2 class="content-header-title float-left m-0 pr-1 mr-1">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="content-header-search mr-1">
            <h3 class="m-0">{{planningViewForm.name}}</h3>
          </div>
          <div class="content-header-actions d-flex">
              <feather-icon class="cursor-pointer" icon="XIcon" size="24" @click="cancel()" /> 
          </div>
        </div>
        <div v-if="isLoadingPlanningView" class="content-loader-center m-0 h-100">
          <div class="text-center flex-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Chargement...</span>
            </div>
            <br />
            Chargement du formulaire...
          </div>
        </div>
        <validation-observer ref="formCreatePlanningView" style="display:contents" v-else> 
          <form-wizard 
            @on-complete="create" 
            ref="wizard" 
            shape="tab"
            color="#0c3571"
            :title="null"
            :subtitle="null"
          >

            <tab-content title="Caractéristiques" class="pt-1">
              <div class="form-content">
                <b-row class="my-0" style="margin: -12px !important">
                  <b-col cols="12">
                      <b-form-group label="Nom du planning *" label-for="name">
                          <validation-provider #default="{ errors }" name="name" rules="required">
                              <b-form-input id="name" v-model="planningViewForm.name" :state="errors.length &gt; 0 ? false : null"></b-form-input><small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>
                  <!-- <b-col cols="3">
                    <b-form-checkbox
                      v-model="planningViewForm.readOnly"
                      value="1"
                    >
                      Lecture seule
                    </b-form-checkbox>
                  </b-col> -->
                  <b-col cols="12">
                      <b-form-group label="Description" label-for="description">
                          <vs-textarea v-model="planningViewForm.description" height="140px" style="min-height:140px; font-size:1rem"></vs-textarea>
                      </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <h5>Paramètres d'affichage</h5>
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      v-model="planningViewForm.isPublicHoliday"
                    >
                      Différencier graphiquement les <span class="font-weight-bolder">jours fériés</span>
                    </b-form-checkbox>
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      v-model="planningViewForm.isCurrentDay"
                    >
                      Différencier graphiquement la <span class="font-weight-bolder">date du jour</span>
                    </b-form-checkbox>
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      v-model="planningViewForm.isShowColGroup"
                    >
                      Afficher la <span class="font-weight-bolder">colonne groupe</span>
                    </b-form-checkbox>
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      v-model="planningViewForm.isAutoOrderedByGroup"
                    >
                      Organiser automatiquement par <span class="font-weight-bolder">groupe alphabétique</span>.<br>
                      Attention, le rendu de ce mode n'est pas visible en mode création.
                    </b-form-checkbox>
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      v-model="planningViewForm.isAutoOrderedByName"
                    >
                      Organiser automatique par <span class="font-weight-bolder">nom de ligne alphabétique</span>
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="12">
                    <h5>Paramètres de gestion</h5>
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      v-model="planningViewForm.isActiveSearch"
                    >
                      Activer la <span class="font-weight-bolder">recherche</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>  
              </div>
            </tab-content>
            <tab-content title="Lignes">
              <b-row class="my-0 h-100">
                  <b-col cols="12" class="py-0">
                    <div class="tabsLines">
                      <div>
                        <b-form-input
                          id="searchLines"
                          v-model="searchLines"
                          placeholder="Rechercher une ligne"
                          style="width: auto; float: right; top: 18px; position: absolute; right: 1rem;z-index: 1;"
                        />
                      </div>
                      <b-tabs class="tabs-content position-relative w-100">
                        <b-tab class="tab-content-h" active title="Collaborateurs">
                          <draggable
                            group="planningLineView"
                            class="list-group list-group-flush cursor-move"
                            tag="ul"
                            :list="planningLinesCollaboratorListMapped"
                          >
                            <transition-group
                              type="transition"
                              name="flip-list"
                              class="flip-list"
                            >
                              <div
                                :key="'planningLinesCollaboratorListMapped'+indexCollaboratorList"
                                v-for="(planningLine,indexCollaboratorList) in planningLinesCollaboratorListMapped"
                              >
                                <b-list-group-item
                                  v-show="planningLine.name.toLowerCase().includes(searchLines.toLowerCase())"
                                  tag="li"
                                >
                                  <div
                                    class="w-100 d-flex justify-content-between align-items-center"
                                  >
                                    <span class="pl-50">
                                      {{ planningLine.name }} 
                                    </span>
                                    <div class="mr-1 d-flex align-items-center" v-if="planningViewForm.linePositions && planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length">
                                      <span class="font-medium-2 font-weight-bold mr-1 text-primary" v-show="planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length>1" :class="planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length>1 ? 'text-warning' :''">
                                        {{planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length}}
                                      </span>
                                      <span class="material-icons-outlined text-primary"  :class="planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length>1 ? 'text-warning' :''">
                                        done
                                      </span>
                                    </div>
                                    <!-- <div>
                                      <span class="material-icons-outlined mr-1" v-if="planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length">
                                        done
                                      </span>
                                      <b-badge variant="primary">
                                        {{planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length}}
                                      </b-badge>
                                    </div> -->
                                  </div>
                                </b-list-group-item>
                              </div>
                            </transition-group>
                          </draggable>
                        </b-tab>
                        <b-tab class="tab-content-h"  title="Affaires">
                          <draggable
                            group="planningLineView"
                            class="list-group list-group-flush cursor-move"
                            tag="ul"
                            :list="planningLinesAffairListMapped"
                          >
                            <transition-group
                              type="transition"
                              name="flip-list"
                              class="flip-list"
                            >
                              <div
                                v-for="(planningLine, indexAffairList) in planningLinesAffairListMapped"
                                :key="'planningLinesAffairList'+indexAffairList"
                              >
                                <b-list-group-item
                                  v-show="planningLine.name.toLowerCase().includes(searchLines.toLowerCase())"
                                  tag="li"
                                >
                                  <div
                                    class="w-100 d-flex justify-content-between align-items-center"
                                  >
                                    <span class="pl-50">
                                      {{ planningLine.name }}
                                    </span>
                                    <div class="mr-1 d-flex align-items-center" v-if="planningViewForm.linePositions && planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length">
                                      <span class="font-medium-2 font-weight-bold mr-1 text-primary" v-show="planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length>1" :class="planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length>1 ? 'text-warning' :''">
                                        {{planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length}}
                                      </span>
                                      <span class="material-icons-outlined text-primary"  :class="planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length>1 ? 'text-warning' :''">
                                        done
                                      </span>
                                    </div>
                                  </div>
                                </b-list-group-item>
                              </div>
                            </transition-group>
                          </draggable>
                        </b-tab>
                        <b-tab class="tab-content-h"  title="Lignes libres">
                          <draggable
                            group="planningLineView"
                            class="list-group list-group-flush cursor-move"
                            tag="ul"
                            :list="planningLinesFreeLineListMapped"
                          >
                            <transition-group
                              type="transition"
                              name="flip-list"
                              class="flip-list"
                            >
                              <div
                                v-for="(planningLine, indexFreeList) in planningLinesFreeLineListMapped"
                                :key="'planningLinesFreeLineList'+indexFreeList"
                                v-show="planningLinesFreeLineListMapped.length>0"
                              >
                                <b-list-group-item
                                  v-show="planningLine.name.toLowerCase().includes(searchLines.toLowerCase())"
                                  tag="li"
                                >
                                  <div
                                    class="w-100 d-flex justify-content-between align-items-center"
                                  >
                                    <span class="d-flex align-items-center">
                                      <span class="material-icons-outlined pl-50 mr-1" @click="popupCreateFreeLine(planningLine.idLine)">
                                        edit
                                      </span>
                                      <span class="pl-50">
                                        {{ planningLine.name }}
                                      </span>
                                    </span>

                                    <div class="mr-1 d-flex align-items-center" v-if="planningViewForm.linePositions && planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length">
                                      <span class="font-medium-2 font-weight-bold mr-1 text-primary" v-show="planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length>1" :class="planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length>1 ? 'text-warning' :''">
                                        {{planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length}}
                                      </span>
                                      <span class="material-icons-outlined text-primary"  :class="planningViewForm.linePositions.filter(elem=>elem.planningLineId==planningLine.planningLineId).length>1 ? 'text-warning' :''">
                                        done
                                      </span>
                                    </div>
                                    
                                  </div>
                                </b-list-group-item>
                              </div>
                            </transition-group>
                          </draggable>
                          <small class="cursor-pointer text-primary" @click="popupCreateFreeLine(0)">+ Ajouter une ligne libre</small>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </b-col>
                </b-row>
            </tab-content>
            <!-- <tab-content title="Partage">
            </tab-content> -->

            <template slot="footer" scope="props">
              <div class="d-flex justify-content-between mt-0" style="border-top: 0px solid #e2e2e2; padding-top: 0.5rem;">
                <div class="wizard-footer-left">
                  <b-button 
                    v-if="props.activeTabIndex > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="props.prevTab()"
                  >
                    <feather-icon icon="ArrowLeftIcon" />
                  Retour
                  </b-button>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="cancel()"
                  >
                    <feather-icon icon="ArrowLeftIcon" />
                    Annuler
                  </b-button>
                  <b-button
                    v-if="planningViewForm.id"
                    variant="outline-danger"
                    class="btn-icon ml-2"
                    @click="archivePlanningViewLocal(planningViewForm.id, planningViewForm.name)"
                  >
                      <feather-icon icon="ArchiveIcon" /> 
                  </b-button>
                </div>
                <div class="wizard-footer-right">
                  <b-button
                    v-if="!props.isLastStep" 
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="props.nextTab()" 
                  >
                    Continuer
                    <feather-icon icon="ArrowRightIcon" />
                  </b-button>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="create"
                  >
                    <feather-icon icon="SaveIcon" v-if="planningViewForm.id" />
                    <feather-icon icon="PlusIcon" v-else />
                    {{ planningViewForm.id ? 'Modifier' : 'Ajouter' }}
                  </b-button>
                </div>
              </div>          
            </template>
          </form-wizard>
       </validation-observer> 
      </b-col>
      <b-col
        cols="6"
        class="render"
      >
      <div class="content-header">
          <div class="content-header-search">
            <h3 class="m-0">Aperçu</h3>
          </div>
          <div class="content-header-actions d-flex">
              <span class="material-icons-outlined cursor-pointer" @click="showPlanning(planningViewForm)">
              open_in_new
              </span>
          </div>
        </div>
        <div class="planningLines" :class="(planningViewForm.linePositions && planningViewForm.linePositions.length > 0) ? '' : 'add-text-draggin'">
              <div class="t-head d-flex justify-content-between" :style="planningViewForm.linePositions && planningViewForm.linePositions.length>0 ? '' : 'display: none !important;'">
                  <div>Nom</div>
                  <div style="width:35%">Groupe</div>
                  <div style="width:70px" class="center">Couleur</div>
                  <div style="width:70px">Action</div>
              </div>
              <draggable
                v-model="planningViewForm.linePositions"
                class="t-row list-group list-group-flush cursor-move"
                
                tag="div"
                group="planningLineView"
                @change="onChange"
                :list="planningViewForm.linePositions"
                :style="(planningViewForm.linePositions && planningViewForm.linePositions.length > 0) ? 'display:contents': ''"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                  :style="(planningViewForm.linePositions && planningViewForm.linePositions.length > 0) ? 'display:contents' : 'border: 2px dashed #e0e0e0;' "
                >
                  <div
                    :key="index"
                    v-for="(ligne, index) of planningViewForm.linePositions"
                    class="t-r d-flex justify-content-between"
                    :class="'typeLine_'+ligne.typeLine"
                    :style="(ligne.color ? 'background:'+ ligne.color + '40 !important' : '')"
                  >
                    <div style="flex:1"  :style="(ligne.color ? 'background:'+ ligne.color + '40 !important' : '')">
                      <div class="focus" >
                        <b-form-input
                          :id="'label-' + index"
                          v-model="ligne.name"
                          class="title"
                        ></b-form-input>
                      </div>
                    </div>
                    <div style="width:35%" :style="(ligne.color ? 'background:'+ ligne.color + '40 !important' : '')">
                      <div class="focus">
                        <b-form-input
                          :id="'group-' + index"
                          v-model="ligne.group"
                          class="title"
                        ></b-form-input>
                      </div>
                    </div>
                    <div style="width:70px" class="d-flex align-items-center justify-content-center" :style="(ligne.color ? 'background:'+ ligne.color + '40 !important' : '')">
                      <div>
                        <validation-provider #default="{ errors }" name="Color">
                        <v-swatches
                            :state="errors.length > 0 ? false : null" 
                            v-model="ligne.color"
                            show-fallback
                            popover-x="left"
                            id="color"
                            :show-border="true"
                            :swatch-size="32"
                            :trigger-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                            :swatch-style="{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' }"
                            :wrapper-style="{ paddingLeft: '8px', paddingRight: '0px' }" 
                        ></v-swatches>
                        </validation-provider>
                      </div>
                    </div>
                    <div style="width:70px" class="d-flex align-items-center justify-content-end" :style="(ligne.color ? 'background:'+ ligne.color + '40 !important' : '')">
                      <div class="cell-action">
                        <span
                          class="handle material-icons cursor-pointer"
                          :class="ligne.isLocked ? 'text-secondary' : 'text-light'"
                          @click="lockedItem(index)"
                          :title="ligne.isLocked ? 'Autoriser la planification sur cette ligne' : 'Interdire la planification sur cette ligne'"
                        >
                          block
                        </span>
                        <span
                          class="handle material-icons cursor-move "
                        >
                          drag_indicator
                        </span>
                        <span
                          class="material-icons-outlined cursor-pointer"
                          @click="removeItem(index)"
                        >
                          clear
                        </span>
                      </div>
                    </div>
                  </div>
                </transition-group>
              </draggable>
                
        </div>
        <div class="d-flex">
          <small class="cursor-pointer text-primary" @click="popupCreateFreeLine(0,3, true)">+ Ajouter un titre</small>
          <small class="pl-2 cursor-pointer text-primary" @click="popupCreateFreeLine(0,4, true)">+ Ajouter un sous-titre</small>
        </div>
      </b-col>
    </b-row>
    <popup-create-free-line ref="popupCreateFreeLine" @returnLine="returnLine"></popup-create-free-line>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {debounce} from 'lodash';
import vSelect from "vue-select";
import draggable from 'vuedraggable'
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import Ripple from "vue-ripple-directive";
import { ETypeLine } from '@/types/api-orisis/enums/enums'
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";

import PopupCreateFreeLine from '@/components/planning/planning-settings/popup-planning-settings/PopupCreateFreeLine';


configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
      regex: 'Doit contenir au moins : 1 majuscule, 1 minuscule, 1 chiffre, et 1 caractère spécial.'
    }
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";

export default {
  props: {
    id: {
      default: 0,
    },
  },
  data () {
    return {
      scrolled: false,
      required,
      url,
      email,
      dataOrigine:{},
      planningViewForm:{},
      searchLines:""
    }
  },
  created(){
    this.popupPlanningViewEvent(this.id)
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
    this.deteleAutocomplete();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    ...mapActions([
      "createPlanningView",
      "fetchPlanningView",
	    "updatePlanningView",
      "archivePlanningView",
      "fetchPlanningLinesFreeLineList",
      "fetchPlanningLinesCollaboratorList",
      "fetchPlanningLinesAffairList"
    ]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(".content-scrollable-sticky-nav ul li a");
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach(link => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    deteleAutocomplete(){
      let elements = document.querySelectorAll('.not-autocomplete input');
      if (!elements) {
        return;
      }
      elements.forEach(element => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    popupPlanningViewEvent(id) { 
      if (id>0) {
        this.fetchPlanningView(id).then(res => {
          this.planningViewForm = res 
          this.planningViewForm.linePositions=this.planningViewForm.linePositions.map(elem => {
            return {
              id: elem.id,
              position: elem.position,
              name: elem.name,
              group:elem.group,
              color:elem.color,
              isLocked: elem.isLocked,
              typeLine: elem.typeLine,
              planningLineId: elem.planningLineId,
            }
          })
          this.dataOrigine=JSON.stringify(this.planningViewForm)
        })
      }else{
        this.initializeForm()
      }
    },
    initializeForm () {
      this.planningViewForm = {
        id: 0,
        name: '',
        linePositions:[],
        description: null,
        isActiveSearch: false,
        isAutoOrderedByGroup: false,
        isAutoOrderedByName: false,
        isCurrentDay: false,
        isDefault: false,
        isPublicHoliday: false,
        isShowColGroup: false,
        order: 1,
        readOnly: false
      }
      this.dataOrigine=JSON.stringify(this.planningViewForm)
    },
    create (e) {
      e.preventDefault()
      this.$refs.formCreatePlanningView
        .validate() 
        .then(success1 => {
          if (success1) {
            if(this.planningViewForm.linePositions.length==0 || this.planningViewForm.linePositions==[]){
              this.planningViewForm.linePositions=null
            }
            if (this.planningViewForm.id) {
              this.updatePlanningView(this.planningViewForm)
            }else{
              this.createPlanningView(this.planningViewForm)
            }
            this.$nextTick(() => {
              this.$tabs.close({to : "/planning/settings/planning-views"})
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    onChange (evt) {
      for (let index = 0; index < this.planningViewForm.linePositions.length; index++) {
        const element = this.planningViewForm.linePositions[index]
        element.position = index
        delete element.idLine
      }
    },
    lockedItem (index) {
      this.planningViewForm.linePositions[index].isLocked=!this.planningViewForm.linePositions[index].isLocked
    },
    removeItem (index) {
      this.planningViewForm.linePositions.splice(index, 1)
    },
    returnLine(val){
      this.planningViewForm.linePositions.push({
        id:0,
        idLine:val.id,
        position: this.planningViewForm.linePositions ? this.planningViewForm.linePositions.length : 0,
        name: val.name,
        group: '',
        color: '',
        isLocked: false,
        typeLine: val.typeLine,
        planningLineId: val.id
      })
    },

    // Controls from
    archivePlanningViewLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourrez à tout moment restaurer ce planning en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver le planning "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archivePlanningView(id);
            if(this.$route.params.routeOrigine){
                this.$tabs.close({to : "/"+this.$route.params.routeOrigine})
              }else{
                this.$tabs.close()
              }
          }
        })
    },
    cancel(){
      if(this.dataOrigine==JSON.stringify(this.planningViewForm)){
        this.$tabs.close()
      }else{
        this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir quitter l\'édition sans enregistrer ?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
          this.$tabs.close()
          }
        })
      }
    },
    showPlanning(planningViewForm){
      this.$router.push({ name: 'planning', params: { id: planningViewForm.id, title: "Planning : "+ planningViewForm.name, tips:"Planning : "+planningViewForm.name } })
    },
    popupCreateFreeLine(id, type = 2, returnFreeLine = false){
      this.$bvModal.show('modal-create-free-line')
      this.$refs['popupCreateFreeLine'].popupCreateFreeLineEvent(id,type, returnFreeLine)
    }
  },
  computed: {
    ...mapGetters([
      "isLoadingPlanningView",
      "planningViewsList",
      "planningLinesFreeLineList",
      "planningLinesCollaboratorList",
      "planningLinesAffairList"
    ]),
    planningLinesCollaboratorListMapped () {
        return this.$store.getters.planningLinesCollaboratorList.map(elem => {
          return {
            id:0,
            position: this.planningViewForm.linePositions ? this.planningViewForm.linePositions.length : 0,
            name: elem.name,
            group: '',
            color: '',
            isLocked: false,
            typeLine: ETypeLine.CollaboratorLine,
            planningLineId: elem.id
          }
        })
    },
    planningLinesAffairListMapped () {
        return this.$store.getters.planningLinesAffairList.map(elem => {
          return {
            id:0,
            position: this.planningViewForm.linePositions ? this.planningViewForm.linePositions.length : 0,
            name: elem.name,
            group: '',
            color: '',
            isLocked: false,
            typeLine: ETypeLine.AffairLine,
            planningLineId: elem.id
          }
        })
    },
    planningLinesFreeLineListMapped () {
        return this.$store.getters.planningLinesFreeLineList.map(elem => {
          return {
            id:0,
            idLine:elem.id,
            position: this.planningViewForm.linePositions ? this.planningViewForm.linePositions.length : 0,
            name: elem.name,
            group: '',
            color: '',
            isLocked: false,
            typeLine: elem.typeLine,
            planningLineId: elem.id
          }
        })
    },
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    FormWizard, 
    TabContent,
    draggable,
    VSwatches,
    PopupCreateFreeLine
  },
  directives: {
    Ripple,
    mask
  },
}
</script>
<style lang="scss">
.content-scrollable-sticky{
  // display: grid; 
  // grid-template-columns: min-content 1fr; 
  position:relative;
  .content-scrollable-sticky-nav{
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>